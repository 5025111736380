.card{
    background: rgba(34, 66, 90, 0.1); 
    backdrop-filter: blur(10px);
    border-radius: 1rem;
    max-width: 18.9rem;
    height: 27.5rem;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.700);
    margin: 1rem 1rem;
    overflow: hidden; 
    position: relative;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

a{
    text-decoration: none;
    color: white;
}

.card img{
    color: black;
    border-radius: 1rem 1rem 0 0;
    height: 9.3rem; 
    object-fit: cover;
    display: block;
    margin: 0;
}


.card .container{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.card .cardTitle{
    font-weight: bold;
    color: #FFF;
    margin-top: 0.8rem;
}

.cardDescription{
    color: #FFF;
    text-align: justify;
    padding: 1rem;
    height: 9.7rem;
    overflow-y: auto !important; 
}

.card:hover {
    transform: translateY(-5px); 
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5); 
}

.card .cardLinkWrapper{
    margin-top: auto;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 1rem;
}

.cardLinkWrapper .iconArrow{
    color: white;
    padding-left: 0.2rem;
}

.button{
    width: 12rem;
    height: 2.8rem;
    border-radius: 0.6rem;
    background: rgba(17, 33, 45, 0.1); 
    backdrop-filter: blur(10px);
    color: white;
    font-size: 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.600);
    border: none;
}

.button:hover{
    background-color: rgba(17, 33, 45, 0.2); ;
    cursor: pointer;
}