.card {
    max-width: 25rem;
    height: 11rem;
    margin: 1rem;
    padding: 1.5rem 1.5rem 0 1.5rem;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.700);
    overflow: hidden; 
    background: rgba(17, 33, 45, 0.2); 
    backdrop-filter: blur(10px); 
    border-radius: 15px;
}

.card .cardTitle {
    font-weight: 500;
    color: white;
}

.cardDescription {
    font-size: 1rem;
    color: white;
    text-align: justify;
    font-weight: lighter;
    padding: 1rem;
    white-space: normal;
    word-wrap: break-word;
}

.college{
    font-size: 0.8rem;
    color: #A9A9A9;
    font-weight: 600;
    margin-top: 0.2rem;
}

@media only screen and (max-width: 700px){
    .card {
        max-width: 18rem;
        height: 10rem;
        margin: 1rem;
        padding: 1.2rem;
    }

    .card .cardTitle {
        font-size: 1rem;
        font-weight: 500;
        color: white;
    }
    
    .cardDescription {
        font-size: 0.9rem;
        padding: 0.4rem;
        white-space: normal;
        word-wrap: break-word;
    }
}

