.fundo {
    background: rgba(17, 33, 45, 0.5); 
    backdrop-filter: blur(10px); 
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1000;
}


.container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    max-width: var(--max-width);
}

.title{
    color: white;
    font-size: 2rem;
    font-weight: semi-bold;
    text-align: left;
    text-decoration: none;
}


.icons{
    color: white;
    padding-left: 20rem;
    align-items: right;
}

.icon{
    padding-left: 1rem;
    cursor: pointer;
}

.icon:hover{
    color: #D8BFD8 !important;
}

@media (max-width: 768px){
    .fundo{
        height: 3.5rem;
    }

    .title{
        font-size: 1.5rem;
    }

    .icons{
        padding-left: 15rem;
    }

    .icon{
        padding-left: 0.5rem;
        cursor: pointer;
    }
}

@media (max-width: 680px){
    .fundo{
        height: 3.5rem;
    }

    .title{
        font-size: 1.5rem;
    }

    .icons{
        padding-left: 5rem;
    }

    .icon{
        padding-left: 0.5rem;
        cursor: pointer;
    }
}